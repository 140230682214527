import React, { useCallback, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
// import useModal from 'components/common/Modal/useModal';
import { PainterToolType } from 'constants/painterTypes';
import PainterPanel from 'components/Painter/PainterPanel';
import { ReaderToolBox } from 'components/ReaderToolBox';
import Toolbox from 'components/Toolbox';
import { useStore, StoreTypes } from 'context';
// import { useMedia } from 'customHooks/media';
import ReaderToolPageSearcher from 'components/ReaderTools/Desktop/ReaderToolComponents/ReaderToolPageSearcher';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent, PainterEvent } from 'events/EventTypes';
import { ReaderToolType, ReaderZoomType, ReaderToolbox, ToolboxCategory } from 'constants/ReaderTools';
import { useWindowSize } from 'customHooks/windowSize';
import ReaderToolComponents from './ReaderToolComponents';
import { useReaderToolsFactory } from './ReaderToolsFactory';
import { useReadUserSettings } from 'customHooks/db';
import { useUpdateUserSettings } from 'customHooks/userSetting';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import Repository from 'repositories/Repository';
import { convertArrayToMap } from 'util/array';
import { Roles } from 'constants/role';
import Reader from 'components/Reader';
import ReaderToolButton from './ReaderToolButton';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import ReaderToolCollectPanel from 'components/ReaderToolCollectPanel';
const { ReaderToolBarRepository } = Repository;

// const MobileToolsModal = () => <div></div>;
// const MobileTools = () => {
//   const [, { openModal }] = useModal();
//   return <div className={styles.mobileReaderTools} onClick={openModal} />;
// };
const PanelState = {
  None: 'None',
  Pen: 'Pen',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line',
  Expression: 'Expression'
};

const ReaderTools = props => {
  const reducers = useStore();
  const [{ role, userId }] = reducers[StoreTypes.user];
  const [{ 
    toolsIsControl, 
    readerToolColor, 
    isToolboxShow: isReaderToolboxShow, 
    isBookmarkShow,
    readerTools,
    readerToolType
  }] = reducers[StoreTypes.reader];
  const [{ books, bookId }] = reducers[StoreTypes.books];
  const [{ rewardInfo,beforeEndAt }] = reducers[StoreTypes.course];
  // const device = useMedia();
  const windowSize = useWindowSize();
  const readUserSettings = useReadUserSettings();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const [{ painterToolType, painterTool, fillType, lineType }] = reducers[StoreTypes.canvas];
  const book = books.find(book => book.bookId === bookId);
  const { LRFlip } = book || {};

  const [panelState, setPanelState] = useState(PanelState.None);

  const [isToolBoxShow, setToolBoxShow] = useState(false);
  const toolBoxCloseHandler = useCallback(() => setToolBoxShow(false), []);

  const [isReaderToolCollectShow,setIsReaderToolCollectShow] = useState(false);

  const readerToolsRef = useRef();
  const ReaderToolsFactory = useReaderToolsFactory();

  const panelCloseHandler = useCallback(() => setPanelState(PanelState.None), []);

  const [leftReaderToolBars, setLeftReaderToolBars] = useState([]);
  const [rightReaderToolBars, setRightReaderToolBars] = useState([]);

  const [toolboxType, setToolboxType] = useState(ToolboxCategory.Settings);
  const [{ readerToolSetting }] = useStore(StoreTypes.settings);
  const updateUserSettings = useUpdateUserSettings();

  useEffect(() => {
    (async () => {
      if (bookId) {
        EventBus.emit({
          event: PainterEvent.LoadReaderToolSettingsEvent,
          payload: { role }
        });
      }
    })();
  }, [bookId, role]);

  useEffect(() => {
    readerToolsRef.current && EventBus.emit({ event: ReaderToolsEvent.SetReaderToolHeight, payload: { readerToolHeight: readerToolsRef.current.offsetHeight } });
  }, [windowSize, readerToolsRef]);

  const setReaderTools = useCallback(readerTools => {
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolsEvent,
      payload: {
        readerTools
      }
    });
  }, [])

  useEffect(() => {
    (async () => {
      if (bookId) {
        const left = ReaderToolBarRepository.getLeftReaderTools();
        setLeftReaderToolBars(left);

        const middle = ReaderToolBarRepository.getMiddleReaderTools();
        setReaderTools(
          [
            ...middle.filter(item => !readerToolSetting.includes(item)).map(item => ({ id: item, isActive: false })),
            ...readerToolSetting.map(item => ({ id: item, isActive: true }))
          ]
        );

        const right = await ReaderToolBarRepository.getRightReaderTools(bookId);
        setRightReaderToolBars(right);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, setReaderTools]);

  const onColorChange = useCallback((color) => {
    EventBus.emit({ event: PainterEvent.ChangeBrushColorEvent, payload: { color } });
  }, []);

  const onLineWidthChange = useCallback(lineWidth => {
    EventBus.emit({ event: PainterEvent.ChangeBrushWidthEvent, payload: { lineWidth } });
  }, []);

  const onPainterChangeHandler = useCallback(painterType => {
    EventBus.emit({ event: PainterEvent.ChangePainterTypeEvent, payload: { painterType } });
  }, []);

  const onBrushChange = useCallback(e => {
    EventBus.emit({ event: PainterEvent.ChangeBrushTypeEvent, payload: { brushType: e.target.value } });
  }, []);

  const onFillTypeChangeHandler = useCallback(fillType => {
    EventBus.emit({ event: PainterEvent.ChangeShapeFillTypeEvent, payload: { fillType } });
  }, []);

  const toolboxTypeChangeHandler = useCallback(toolType => {
    setToolboxType(toolType)
  }, [])

  const onExpressionHandler = useCallback((expressionType) => {
    EventBus.emit({
      event: ReaderToolsEvent.PlayExpression,
      payload: { expressionType }
    });
    EventBus.emit({ event: ReaderToolsEvent.SendExpressionEvent, payload: { expressionType, rewardInfo } });
    setPanelState(PanelState.None)
  }, [rewardInfo])

  const toolboxFilter = useCallback(
    arr => arr.filter(item => ReaderToolbox[toolboxType] && ReaderToolbox[toolboxType].includes(item.id) && !item.isActive),
    [toolboxType])

  const readerToolsFilter = useCallback(arr => {
    return arr.filter(item => item.isActive)
  }, [])

  const resetClickHandler = useCallback(async () => {
    const middle = ReaderToolBarRepository.getMiddleReaderTools();
    const defaultTools = ReaderToolBarRepository.getDefaultReaderTools();

    setReaderTools(
      [
        ...middle.filter(item => !defaultTools.includes(item)).map(item => ({ id: item, isActive: false })),
        ...defaultTools.map(item => ({ id: item, isActive: true }))
      ]
    )

    updateUserSettings({ readerToolSetting: defaultTools }, false);

  }, [setReaderTools, updateUserSettings])

  const onLineTypeChangeHandler = useCallback(lineType => {
    EventBus.emit({ event: PainterEvent.ChangelineTypeEvent, payload: { lineType } });
  }, [])

  const fourZoomClickHandler = (type) => {
    EventBus.emit({ event: ReaderToolsEvent.SetReaderZoomEvent, payload: { type } })
  }

  const fourZoomInButtonRef = useRef();
  useOutsideAlerter(fourZoomInButtonRef, () => {
    setIsReaderToolCollectShow(false);
  })

  return (
    <div id="Reader-Tools" ref={readerToolsRef} style={{ backgroundColor: readerToolColor }}>
      <div
        className={classnames(
          styles.readerTools,
          styles[props.className],
          styles[!toolsIsControl && "hide"]
        )}
      >
        {panelState === PanelState.Pen &&
          <PainterPanel.Pen
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
          />
        }
        {panelState === PanelState.Highlighter &&
          <PainterPanel.Pen
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
          />}
        {panelState === PanelState.Shape &&
          <PainterPanel.Shape
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
            onLineTypeChange={onLineTypeChangeHandler}
          />}
        {panelState === PanelState.Line &&
          <PainterPanel.Line
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onPainterHandler={onPainterChangeHandler}
          />}
        {panelState === PanelState.Expression &&
          <PainterPanel.Expression
            onPanelClose={panelCloseHandler}
            onExpressionHandler={onExpressionHandler}
          />}
        {isToolBoxShow &&
          <ReaderToolBox
            onClose={toolBoxCloseHandler}
          />}
          {
              role !== Roles.ADMIN && (
                <div className={styles.toolBar}>
                  <div className={classnames(styles.toolsGroup, styles.left)}>
                    <div className={styles.tool}>
                      <ReaderToolPageSearcher
                        type={ReaderToolType.PageSearcher}
                        clickHandlerParameters={[book,LRFlip]}
                      />
                    </div>    
                  </div>
                  <div className={classnames(styles.toolsGroup, styles.middle)}>
                    <ReaderToolButton 
                      iconName="Mouse"
                      isActive={readerToolType === ReaderToolType.Drag}
                      onClick={() => toolsEventMap[ReaderToolType.Drag].clickHandler()}
                    />
                    <div className={styles.divide} />
                    <ReaderToolButton 
                      iconName="Pen"
                      isActive={readerToolType === ReaderToolType.Painting}
                      activeColor={painterTool[PainterToolType.Pen].originColor}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Painting].clickHandler();
                        setPanelState(PanelState.Pen)
                      }}
                    />
                    <ReaderToolButton 
                      iconName="Markerpen"
                      isActive={readerToolType === ReaderToolType.Highlighter}
                      activeColor={painterTool[PainterToolType.Highlighter].originColor}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Highlighter].clickHandler();
                        setPanelState(PanelState.Highlighter)
                      }}
                    />
                    <ReaderToolButton 
                      iconName="Eraser"
                      isActive={readerToolType === ReaderToolType.Delete}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Delete].clickHandler();
                      }}
                    />
                    <ReaderToolButton 
                      iconName="Text"
                      isActive={readerToolType === ReaderToolType.Text}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Text].clickHandler();
                      }}
                    />
                    {
                      role===Roles.ONECLASS_TEACHER&&<ReaderToolButton 
                      iconName="Emoji"
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Expression].clickHandler();
                        setPanelState(PanelState.Expression)
                      }}
                      />
                    }
                    <ReaderToolButton 
                      iconName="Photo"
                      isActive={readerToolType === ReaderToolType.Img}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Img].clickHandler();
                      }}
                    />
                    <ReaderToolButton 
                      iconName="Hand"
                      isActive={readerToolType === ReaderToolType.Select}
                      onClick={() => {
                        toolsEventMap[ReaderToolType.Select].clickHandler();
                      }}
                    />
                    
                    <div className={styles.divide} />

                    <ReaderToolButton 
                      iconName="ZoomIn"
                      isActive={readerToolType === ReaderToolType.IntervalZoomIn}
                      onClick={() => toolsEventMap[ReaderToolType.IntervalZoomIn].clickHandler()}
                    />
                    <ReaderToolButton 
                      iconName="FourZoomIn"
                      onClick={() => setIsReaderToolCollectShow(prev => !prev)}
                      ref={fourZoomInButtonRef}
                    >
                      <ReaderToolCollectPanel isShow={isReaderToolCollectShow}>
                        <div className={styles.toolsGroup}>
                          <ReaderToolButton 
                            iconName="FourZoomInLeftTop"
                            onClick={() => fourZoomClickHandler(ReaderZoomType.LeftTop)}
                          />
                          <ReaderToolButton 
                            iconName="FourZoomInRightTop"
                            onClick={() => fourZoomClickHandler(ReaderZoomType.RightTop)}
                          />
                          <ReaderToolButton 
                            iconName="FourZoomInLeftBottom"
                            onClick={() => fourZoomClickHandler(ReaderZoomType.LeftBottom)}
                          />
                          <ReaderToolButton 
                            iconName="FourZoomInRightBottom"
                            onClick={() => fourZoomClickHandler(ReaderZoomType.RightBottom)}
                          />
                        </div>
                      </ReaderToolCollectPanel>
                    </ReaderToolButton>
                    <ReaderToolButton 
                      iconName="Orignalsize" 
                      onClick={() => toolsEventMap[ReaderToolType.AllScreen].clickHandler()}
                    />
                    {
                      beforeEndAt && <ReaderToolButton 
                      iconName="ResetLastAnnatation" 
                      onClick={() => 
                        EventBus.emit({
                          event: ReaderToolsEvent.ResetCourseAnnotationEvent
                        })
                      }
                    />
                    }
                    <div className={styles.divide} />

                    <ReaderToolButton 
                      iconName="Trashcan"
                      onClick={() => toolsEventMap[ReaderToolType.DeleteAll].clickHandler()}
                    />
                  </div>
                  <div className={classnames(styles.toolsGroup, styles.right)}>
                    <ReaderToolButton 
                      iconName="Bookmark"
                      onClick={() => toolsEventMap[ReaderToolType.Bookmark].clickHandler()}
                      isActive={isBookmarkShow}
                      activeColor="#4C99A5"
                    />
                  </div>
                </div>
              )
          }
      </div>
    </div>
  );
};

export default ReaderTools;
