import React, { useEffect, useState } from 'react';
import Reader from 'components/Reader/index';
import { useStore, StoreTypes } from 'context';
import { useLocation, useParams } from 'react-router-dom';
import {
  ContentShare, useContentShareState, MeetingProvider, useMeetingManager, useMeetingStatus, lightTheme, useAudioVideo, Severity, NotificationProvider, Notification, NotificationGroup, useNotificationState, ActionType, useNotificationDispatch
} from 'amazon-chime-sdk-component-library-react';
import LiveStreamMasterPanel from 'components/LiveStreamMasterPanel';
import LiveStreamViewerPanel from 'components/SideToolContents/LiveStreamViewerPanel'
import LiveStreamAdminPanel from 'components/SideToolContents/LiveStreamAdminPanel'
import { useAnnotationChecker } from 'customHooks/annotationChecker';
import { Roles } from 'constants/role';
import Sidebar from 'components/Sidebar';
import styles from './index.module.scss';
import classnames from 'classnames';
import { MeetingStatus } from 'constants/meetingStatus';
import Loading from 'components/Loading';
import { COURSE_TYPE } from 'constants/courseTypes';
import LiveStreamSettingPopOver from 'components/LiveStreamSettingPopOver';
import { useChimeError } from 'customHooks/chimeError'
import { useDeleteAnnotation } from "customHooks/db";



const readerClass = (role) => {
  switch (role) {
    case Roles.ONECLASS_TEACHER:
    case Roles.ONECLASS_STUDENT:
      return styles.studentMode;
    default:
      return styles.otherMode;
  }
}

const LiveStreamSelect = React.memo(({ roomName, userName, userId, chimeRole, roleType, courseType, isStreamPannel }) => {
  if (!roomName || !userName || !userId || !chimeRole) return null;
  let role = "teacher"
  switch (chimeRole) {
    case Roles.TUTOR:
    case Roles.ONECLASS_TEACHER:
      role = "teacher"
      break;
    case Roles.ADMIN:
    case Roles.TUTOR_USER:
    case Roles.ONECLASS_STUDENT:
      role = "student"
      break;
    default:
      break;
  }

  // const roomInfo = {
  //   roomName: roomName,
  //   userName: userName,
  //   role: role,
  //   userId: `${userId}$${chimeRole}`
  // }

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const audioVideo = useAudioVideo();
  const { showNotification } = useChimeError();

  const joinMeeting = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_CHIME_API_URL}/join?title=` + encodeURIComponent(roomName) +
      '&name=' + encodeURIComponent(userName) +
      '&region=' + encodeURIComponent('ap-northeast-1') +
      '&role=' + encodeURIComponent(role) +
      '&userId=' + encodeURIComponent(`${userId}$${chimeRole}`),
      {
        method: 'POST'
      },
    );

    const { data } = await response.json();
    const joinData = {
      meetingInfo: data.meeting,
      attendeeInfo: data.attendee
    };
    await meetingManager.join(joinData);
    await meetingManager.start();
  };

  const processMessionEnd = (statusCode) => {
    switch (statusCode) {
      case 2:
        showNotification(Severity.ERROR, "此帳號已在其他裝置上開啟此課堂", false, false);
        break;
      case 6:
        showNotification(Severity.ERROR, "課堂已結束", false, false);
        break;
      case 18://連線逾時中斷
      case 19://不明原因中斷
        showNotification(Severity.ERROR, "連線已中斷，重新連線中", true, false);
        joinMeeting(); //重新加入會議
        break;
    }

  };


  useEffect(() => {


    const myObserver = {
      audioVideoDidStart: () => {
        showNotification(Severity.SUCCESS, '已上線', true);
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          showNotification(Severity.INFO, '重新連線中', false, false);
        }
        else {
          showNotification(Severity.INFO, '連線中');
        }
      },
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        showNotification(Severity.ERROR, '連線已中斷');
        processMessionEnd(sessionStatusCode);
      },
      connectionDidBecomeGood: () => {
        showNotification(Severity.SUCCESS, '連線品質正常', true);
      },
      connectionDidBecomePoor: () => {
        showNotification(Severity.WARNING, '目前連線品質不佳');
      },
      connectionDidSuggestStopVideo: () => {
        showNotification(Severity.ERROR, '目前連線品質不佳，對方可能無法取得你的畫面', false, false);
      },
    };



    if (audioVideo) {
      audioVideo.addObserver(myObserver);
    }
  }, [audioVideo]);



  useEffect(() => {


    joinMeeting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (courseType === COURSE_TYPE.NORMAL) {
    return null;
  }

  if (meetingStatus === MeetingStatus.Loading) {
    return <Sidebar vertical isShow={true} isFloat={false}>
      <Loading />
    </Sidebar>
  }

  if (meetingStatus === MeetingStatus.Succeeded) {
    return <Sidebar className={styles.liveStreamSideBar} vertical isShow={isStreamPannel} isFloat={false}>
      <LiveStreamSettingPopOver />
      {
        (chimeRole === Roles.ONECLASS_TEACHER || chimeRole === Roles.TUTOR) && (
          <LiveStreamMasterPanel />
        )
      }
      {
        (chimeRole === Roles.ONECLASS_STUDENT || chimeRole === Roles.TUTOR_USER) && (
          <LiveStreamViewerPanel />
        )
      }
      {
        (chimeRole === Roles.ADMIN) && (
          <LiveStreamAdminPanel />
        )
      }
    </Sidebar>
  }
})

const ReaderWithContentShare = React.memo(({ role, ...props }) => {
  const { sharingAttendeeId } = useContentShareState();
  return <div className={styles.contentWrapper}>
    {(role === Roles.TUTOR_USER || role === Roles.ADMIN || role === Roles.ONECLASS_STUDENT) && sharingAttendeeId && (
      <div className={styles.contentShare}>
        <ContentShare />
      </div>
    )}
    <Reader {...props} />
  </div>
})

const ReaderActivity = (props) => {
  const { roomId } = props
  useAnnotationChecker(props);
  const [{ role, name, userId }] = useStore(StoreTypes.user);
  const [{ chimeRole, courseType }] = useStore(StoreTypes.course);
  const [{ isLiveStreamPanelShow }] = useStore(StoreTypes.reader);
  const { notifications } = useNotificationState();
  const dispatch = useNotificationDispatch();
  const { sharingAttendeeId } = useContentShareState();
  const [isStreamPannel, setIsStreamPannel] = useState(false);

  const notificationItems =
    notifications.map(({ id, ...rest }) => (
      <Notification
        key={id}
        {...rest}
        onClose={() => dispatch({ type: ActionType.REMOVE, payload: id })}
      />
    ));

  useEffect(() => {
      setIsStreamPannel(!Boolean(sharingAttendeeId))
  }, [role, sharingAttendeeId])

  return role && <div className={classnames(styles.LiveStreamContent, readerClass(role))}>
    <ReaderWithContentShare {...props} role={chimeRole} />
    {
      isLiveStreamPanelShow && chimeRole && (
        <LiveStreamSelect
          isTeacher={chimeRole === Roles.ONECLASS_TEACHER}
          roomName={roomId}
          userName={name}
          userId={userId}
          chimeRole={chimeRole}
          courseType={courseType}
          isStreamPannel={role === Roles.ONECLASS_TEACHER ? true : isStreamPannel}
        />
      )
    }
    <NotificationGroup>
      {notificationItems}
    </NotificationGroup>
  </div>
}

const ChimeWrapper = () => {

    const [{ annotationId }] = useStore(StoreTypes.annotation);
	const { deleteAnnotation } = useDeleteAnnotation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let role = query.get('role');
  let activityId = query.get('activityId');
  let teacherId = query.get('teacherId');
  let interactiveObjectId = query.get('interactiveObjectId');
  let roomId = query.get('roomId');
  let studentId = query.get('studentId');
  let timeSpanId = query.get('timeSpanId');
  let code = query.get('code');
  let otp = query.get('otp');
  const isBeforeunloadAlert = query.get("beforeunload");
  const { bookId } = useParams();

  useEffect(() => {
		if (isBeforeunloadAlert === "false") {
			annotationId && deleteAnnotation(annotationId);
		}
  }, [isBeforeunloadAlert,annotationId, deleteAnnotation]);

  return (
    <ReaderActivity
      bookId={bookId}
      paramsRole={role}
      studentId={studentId}
      paramsTeacherId={teacherId}
      paramsInteractiveObjectId={interactiveObjectId}
      timeSpanId={timeSpanId}
      roomId={roomId}
      code={code}
      otp={otp}
      paramsActivityId={activityId}
    />
  )
}


export default ChimeWrapper


